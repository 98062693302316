import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EventList } from './EventList';
import { api } from '../axiosclient';
import Header from './Header';
import PasscodeInput from './Passcode';



export function UserVerification() {
    const { slug } = useParams();
    const [authenticationStatus, setAuthenticationStatus] = useState(false);
    const [phoneNumberAlternate, setPhoneNumberAlternate] = useState("");
    const [phoneNumberStatus, setPhoneNumberStatus] = useState("Fetching beautiful moments...");

    useEffect(() => {
        const authtoken = sessionStorage.getItem('authtoken');
        if (authtoken != null) {
            setAuthenticationStatus(true);
        } else {
            api.post('/contactnumber', {
                url: slug,
            })
                .then(response => {
                    console.log(response.data);
                    setPhoneNumberAlternate(response.data);
                })
                .catch(error => {
                    setPhoneNumberStatus(`Error getting contact: ${error}`);
                });
        }
    }, [slug, authenticationStatus]);

    if (authenticationStatus) {
        return <EventList />
    } else {
        if (phoneNumberAlternate.length === 0) {
            return (<h1>{phoneNumberStatus}</h1>);
        } else {
            return (
                <div style={{backgroundColor:"#f8f8f8", minHeight:"100vh"}}>
                    <Header />
                    <PasscodeInput alternateDigits={phoneNumberAlternate} setAuthenticationStatus={setAuthenticationStatus} slug={slug}/>
                </div>
            );
        }
    }


}