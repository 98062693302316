import './App.css';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import {Home} from './Pages/Home';
import { UserVerification } from './Pages/UserVerification';
import { PhotoView } from './Pages/PhotoView';
import { GuestVerification } from './Pages/GuestVerification';
import UserForm from './Pages/GuestInfo';


function App() {
  return (
    <div className="App">
      <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/guest/:event_id" element={<UserForm />}/>
                <Route path="/:slug/:event_name" element={<PhotoView />} />
                <Route path="/:slug" element={<UserVerification />} />
                <Route path="/:slug/:event_name/findme" element={<GuestVerification />} />
            </Routes>
        </Router>
    </div>
  );
}

export default App;
