import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import { Gallery } from "react-grid-gallery";
import { Lightbox, Slide, useLightboxState } from "yet-another-react-lightbox";
import Download from "yet-another-react-lightbox/plugins/download";
import "yet-another-react-lightbox/styles.css";
import { CustomImage } from "../images";
import InfiniteScroll from 'react-infinite-scroll-component';
import { api } from '../axiosclient';
import Header from "./Header";
import AlertDialog from "./AlertDownload";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ThreeDots } from 'react-loader-spinner';
import { useMediaQuery } from 'react-responsive';
import AvatarGrid from './AvatarGrid';
import { relative } from "path";


export function PhotoView() {
  const { event_name } = useParams();
  const [clientName, setClientName] = useState("");
  const [images, setImages] = useState<CustomImage[]>([]);
  const [myPhotosBeforeFiltering, setMyphotosBeforeFiltering] = useState<CustomImage[]>([]);
  const [myPhotos, setMyphotos] = useState<CustomImage[]>([]);
  const [shortlistedImages, setShortListedImages] = useState<CustomImage[]>([]);
  const [slides, setSlides] = useState<Slide[]>([]);
  const [index, setIndex] = useState(-1);
  const [hasmoreimages, sethasmoreimages] = useState(true);
  const [photocount, setphotocount] = useState(0);
  const [downloadAlertState, setDownloadAlertState] = useState(false);
  const [viewOnlySelected, setViewOnlySelected] = useState(false);
  const [imagePageRequest, setImagePageRequest] = useState(0);
  const [loading, setLoading] = useState(false);
  const [containerHeight, setContainerHeight] = useState(0);
  const [downloadStatus, setDownloadStatus] = useState("notstarted");
  const [downloadLink, setDownloadLink] = useState('');
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const [isFaceSearch, setIsFaceSearch] = useState(false);
  const [identityAvatars, setIdentityAvatats] = useState<String[]>([]);
  const [selectedAvatars, setSelectedAvatars] = useState([]);
  const [selectedAvatarCount, setSelectedAvatarCount] = useState(0);


  const desktopRowHeight = 300;
  const mobileRowHeight = 200;
  const PAGINATE_SIZE = 30;

  const fetchDownloadStatus = async () => {
    api.post('/bulkdownload/status', {
      download_id: sessionStorage.getItem("downloadId"),
    }).then(res => {
      console.log(res.data);
      setDownloadStatus(res.data.status);
      if (res.data.status == "ready") {
        setDownloadLink(res.data.download_link)
      }
    }
    ).catch(error => {
      console.log(`error getting status: ${error}`);
    });
  };

  const handleDownloadAgree = () => {
    api.post('/client/event/images/shortlisted', {
      event_id: sessionStorage.getItem('eventid'),
    })
      .then(response => {
        let selectedImageIds: string[] = [];
        for (var img of response.data.images) {
          console.log(img)
          selectedImageIds.push(img.key);
        }
        api.post('/bulkdownload', {
          event_id: sessionStorage.getItem('eventid'),
          photoids: selectedImageIds
        }).then(res => {
          sessionStorage.setItem('downloadId', res.data);
          fetchDownloadStatus();
          setInterval(fetchDownloadStatus, 5000); // Poll every 5 seconds
          setDownloadAlertState(false);
        }
        ).catch(error => {
          console.log(`Error downloading images: ${error}`);
          setDownloadAlertState(false);
        });
      })
      .catch(error => {
        console.log(`Error getting images: ${error}`);
        setDownloadAlertState(false);
      });
  };


  const handleSelect = (index: number) => {
    // call the api to like the selected images
    api.post('/image/shortlist', {
      event_id: sessionStorage.getItem('eventid'),
      image_id: images[index].key,
      selected: !images[index].isSelected
    })
      .then(response => {
        images[index].isSelected = !images[index].isSelected
        if (images[index].isSelected) {
          setphotocount(photocount + 1)
        } else {
          setphotocount(photocount - 1)
        }
        setImages(images);
      })
      .catch(error => {
        console.log(`Error setting shortlist: ${error}`);
      });
  };

  const fetchMoreImages = () => {
    console.log("fetching more images", imagePageRequest);
    if (!loading) {
      setLoading(true);
      const newImages = myPhotos.slice(imagePageRequest * PAGINATE_SIZE, (imagePageRequest + 1) * PAGINATE_SIZE);
      api.post('/getimageurls', {
        images: newImages,
      })
        .then(res => {
          const updated_images = [...images, ...res.data.images];
          setImages(updated_images);
          const sl = updated_images.map(({ original, width, height }: CustomImage) => ({
            src: original,
            width,
            height,
          }));
          setSlides(sl);
          sethasmoreimages(myPhotos.length > (imagePageRequest + 1) * PAGINATE_SIZE);
          setImagePageRequest(imagePageRequest + 1);
          setLoading(false);

        })
        .catch(error => {
          console.log(`Error getting presigned urls: ${error}`);
          setLoading(false);
        });
    }
  };

  const handleClick = (index: number, item: CustomImage) => setIndex(index);

  useEffect(() => {
    if (containerHeight > 0 && containerHeight < window.innerHeight && hasmoreimages) {
      fetchMoreImages()
    }
  }, [containerHeight, imagePageRequest]);

  useEffect(() => {
    var filteredItems = myPhotosBeforeFiltering;
    if (selectedAvatars.length > 0) {
      filteredItems = myPhotosBeforeFiltering.filter(item =>
        selectedAvatars.every(av => item.identities?.includes(av))
      );
      filteredItems = filteredItems.sort((a, b) => a.identities!!.length - b.identities!!.length);
    } else if (selectedAvatarCount == 0) {
      return
    }
    setSelectedAvatarCount(selectedAvatars.length);
    setMyphotos(filteredItems);
    const newImages = filteredItems.slice(0, PAGINATE_SIZE);
    api.post('/getimageurls', {
      images: newImages,
    })
      .then(res => {
        const updated_images = res.data.images;
        setImages(updated_images);
        const sl = updated_images.map(({ original, width, height }: CustomImage) => ({
          src: original,
          width,
          height,
        }));
        setSlides(sl);
        sethasmoreimages(filteredItems.length > PAGINATE_SIZE);
        setImagePageRequest(1);
      })
      .catch(error => {
        console.log(`Error getting presigned urls: ${error}`);
      });

  }, [selectedAvatars]);


  useEffect(() => {

    console.log("use effect called");
    const myDiv = document.getElementById('imagegallery');
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        const newHeight = entry.contentRect.height;
        setContainerHeight(newHeight);
      }
    });

    if (myDiv) {
      resizeObserver.observe(myDiv);
    }

    api.get('/contactname', {})
      .then(response => {
        console.log(response.data);
        setClientName(response.data);
      })
      .catch(error => {
        console.log(`Error getting client name ${error}`);
      });


    api.post('/client/event/identities', {
      event_id: sessionStorage.getItem('eventid'),
    })
      .then(response => {
        console.log(response.data);
        let avatarids = []
        for (var img of response.data.identities) {
          avatarids.push(img.img)
        }
        console.log(avatarids);
        setIdentityAvatats(avatarids);
      })
      .catch(error => {
        console.log(`Error getting identities: ${error}`);
      });


    api.post('/client/event/images', {
      event_id: sessionStorage.getItem('eventid'),
    })
      .then(response => {
        var count_selected = 0;
        for (var img of response.data.images) {
          if (img.isSelected) {
            count_selected += 1;
          }
        }
        setphotocount(count_selected);
        setMyphotosBeforeFiltering(response.data.images);
        setMyphotos(response.data.images);
        const newImages = response.data.images.slice(imagePageRequest * PAGINATE_SIZE, (imagePageRequest + 1) * PAGINATE_SIZE);
        api.post('/getimageurls', {
          images: newImages,
        })
          .then(res => {
            const updated_images = [...images, ...res.data.images];
            setImages(updated_images);
            const sl = updated_images.map(({ original, width, height }: CustomImage) => ({
              src: original,
              width,
              height,
            }));
            setSlides(sl);
            sethasmoreimages(response.data.images.length > (imagePageRequest + 1) * PAGINATE_SIZE);
            setImagePageRequest(imagePageRequest + 1);
          })
          .catch(error => {
            console.log(`Error getting presigned urls: ${error}`);
          });
      })
      .catch(error => {
        console.log(`Error getting images: ${error}`);
      });
  }, []);


  useEffect(() => {

    if (viewOnlySelected) {

      api.post('/client/event/images/shortlisted', {
        event_id: sessionStorage.getItem('eventid'),
      })
        .then(response => {
          let nextImages: CustomImage[] = [];
          for (var img of response.data.images) {
            nextImages.push(img);
          }
          setShortListedImages(nextImages);
        })
        .catch(error => {
          console.log(`Error getting images: ${error}`);
        });
    }
  }, [viewOnlySelected]);

  const FavButton: React.FC = () => {
    const { currentIndex } = useLightboxState();

    const handleClick = () => {
      handleSelect(currentIndex);
    };

    return (
      <IconButton
        size="large"
        color="inherit"
        sx={{ mr: 0 }}
        style={{ color: "white" }}

        onClick={handleClick}
      >
        {images[currentIndex].isSelected ? <CheckCircleIcon style={{ color: "#1e81b0" }} /> : <CheckCircleOutlineIcon />}
      </IconButton>

    );
  };

  return (
    <div>
      <div style={{ position: "sticky", top: 0, left: 0, zIndex: 10, width: "100%" }}>
        <Header photocount={photocount} setAlertState={setDownloadAlertState} viewOnlySelected={viewOnlySelected} setViewOnlySelected={setViewOnlySelected} eventTitle={`${clientName}'s ${event_name}`} downloadStatus={downloadStatus} downloadLink={downloadLink} isFaceSearch={isFaceSearch} setIsFaceSearch={setIsFaceSearch} />
        {isFaceSearch ? <AvatarGrid avatars={identityAvatars} selectedAvatars={selectedAvatars} setSelectedAvatars={setSelectedAvatars} /> : <></>}
      </div>

      <AlertDialog alertState={downloadAlertState} setAlertState={setDownloadAlertState} photocount={photocount} handleAgree={handleDownloadAgree} />


      {viewOnlySelected ?
        <>
          <div style={{ padding: isMobile ? "0px 0px" : "0px 20px" }}>
            <Gallery
              rowHeight={isMobile ? mobileRowHeight : desktopRowHeight}
              images={shortlistedImages.map((image: CustomImage, i: number) =>
                image.isSelected ? { ...image, isSelected: !image.isSelected } : image
              )}
              onClick={handleClick}
              enableImageSelection={false}
            />
          </div>
          <Lightbox
            slides={shortlistedImages.map(({ original, width, height }: CustomImage) => ({
              src: original,
              width,
              height,
            }))}
            open={index >= 0}
            index={index}
            toolbar={{
              buttons: [
                "close"
              ]
            }}
            close={() => setIndex(-1)}
            plugins={JSON.parse(sessionStorage.getItem('allow_download')!) ? [Download] : []}
          /></>
        :
        <>
          <div style={{ padding: isMobile ? "0px 0px" : "0px 20px" }}>

            <InfiniteScroll
              dataLength={images.length}
              next={fetchMoreImages}
              hasMore={hasmoreimages}
              loader={<div style={{ display: "flex", justifyContent: "center" }}><ThreeDots height={"80"} width={"80"} radius={"9"} color={"#808080"} ariaLabel={"three-dots-loading"} /></div>}
              endMessage={viewOnlySelected ? <></> :
                <p style={{ textAlign: 'center' }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
              <div id="imagegallery">
                <Gallery
                  rowHeight={isMobile ? mobileRowHeight : desktopRowHeight}
                  images={images}
                  onClick={handleClick}
                  enableImageSelection={isMobile ? false : true}
                  onSelect={handleSelect}
                />
              </div>
            </InfiniteScroll>

          </div>

          <Lightbox
            slides={slides}
            open={index >= 0}
            index={index}
            toolbar={{
              buttons: [
                <FavButton key="favbtn" />,
                "close"
              ]
            }}
            close={() => setIndex(-1)}
            plugins={JSON.parse(sessionStorage.getItem('allow_download')!) ? [Download] : []}
          /></>

      }
    </div>
  );
}