import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useMediaQuery } from 'react-responsive';
import Box from '@mui/material/Box';
import logo from "./cosec-logo.png";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000', // Primary color
    },
    secondary: {
      main: '#dc004e', // Secondary color
    },
  },

  typography: {
    fontFamily: ['Pacifico', 'cursive'].join(','),
    fontWeightBold: 700,
  },
});

type headerProps = {
  photocount?: number,
  setAlertState?: React.Dispatch<React.SetStateAction<boolean>>,
  viewOnlySelected?: boolean,
  setViewOnlySelected?: React.Dispatch<React.SetStateAction<boolean>>;
  eventTitle?: string,
  downloadStatus?: string,
  downloadLink?: string,
  isFaceSearch?: boolean,
  setIsFaceSearch?: React.Dispatch<React.SetStateAction<boolean>>;
}

function toTitleCase(str: string) {
  return str.replace(
    /\w\S*/g,
    text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
}

export default function Header({ photocount, setAlertState, viewOnlySelected, setViewOnlySelected, eventTitle, downloadStatus, downloadLink, isFaceSearch, setIsFaceSearch }: headerProps) {

  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  console.log(downloadLink);

  const handleDownload = () => {
    if (setAlertState) {
      setAlertState(true);
    }
  };

  const handleViewOnlySelect = () => {
    if (setViewOnlySelected) {
      setViewOnlySelected(!viewOnlySelected)
    }
  }

  const handleDownloadTrigger = (download_link: string) => {
    const link = document.createElement('a');
    link.href = download_link;
    link.download = 'photos.zip';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const toggleFaceSearch = () => {
    if (setIsFaceSearch) {
      setIsFaceSearch(!isFaceSearch);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="sticky" style={{ backgroundColor: "#FFFFFF" }}>
        {eventTitle ? <Toolbar>
          <Box component="img" src={logo} alt="Logo" sx={{ height: 45, mr: 0.7 }} />
          <Typography variant="h6" fontWeight="fontWeightBold" component="div" sx={{ color: theme.palette.primary.main }}>
            {toTitleCase(eventTitle!!)}
          </Typography>

          <div style={{ marginLeft: 'auto' }}>

            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 0, color: theme.palette.primary.main }}
              onClick={handleViewOnlySelect}
            >
              <Badge
                badgeContent={photocount} // Replace with the desired number
                color="primary"
              >
                {viewOnlySelected ? <FavoriteIcon sx={{ color: 'rgba(0, 0, 0, 0.5)' }} /> : <FavoriteBorderIcon sx={{ color: 'black' }} />}

              </Badge>
            </IconButton>

            {isMobile ? <></> : <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ ml: 1, mr: 0, color: theme.palette.primary.main }}
            onClick={toggleFaceSearch}
          ><PersonSearchIcon />
          </IconButton>}

            {isMobile || !JSON.parse(sessionStorage.getItem('allow_download')!) ? <></> : downloadStatus == "notstarted" ? <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
              sx={{ ml: 1, mr: 0, color: theme.palette.primary.main }}

              onClick={handleDownload}
            ><DownloadIcon />
            </IconButton> : downloadStatus == "ready" ?
              <Button variant="contained" sx={{ ml: 1 }} onClick={() => {
                handleDownloadTrigger(downloadLink!)
              }}>{downloadStatus}</Button> :
              <Button sx={{ ml: 1 }} variant="contained">{downloadStatus}...</Button>
            }

          </div>

        </Toolbar> : <Toolbar>
          <Box component="img" src={logo} alt="Logo" sx={{ height: 45, mr: 0.7 }} />
          <Typography variant="h6" fontWeight="fontWeightBold" component="div" sx={{ mr: 2, color: theme.palette.primary.main }}>
            Cosecant AI
          </Typography>
        </Toolbar>}

      </AppBar>
    </ThemeProvider>
  );
}
