import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from "react";
import { api } from '../axiosclient';
import Button from '@mui/material/Button';
import Header from './Header';
import { useMediaQuery } from 'react-responsive';


interface Event {
    id: string;
    name: string;
    can_view: boolean;
    can_download: boolean;
}


export function EventList() {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const navigate = useNavigate();
    const location = useLocation();
    const [eventData, setEventData] = useState<Event[] | null>(null);

    const addPathToCurrentURL = (newPath: string) => {
        const currentPath = location.pathname;
        const newURL = `${currentPath}/${newPath}`.replace(/\/+/g, '/');
        navigate(newURL);
    };

    useEffect(() => {
        api.get('/client/events')
            .then(response => {
                setEventData(response.data);
            })
            .catch(error => {
                console.log(`Error getting contact: ${error}`);
            });

    }, []);

    return (
        <div>
            <Header />
            <h1 style={{ fontFamily: "serif" }}>Choose Event</h1>
            {eventData && (
                <div style={{ display: 'flex', flexWrap: "wrap", justifyContent: "center", flexDirection: 'column', alignItems: 'center', gap: '20px', width: "auto" }}>
                    {eventData.map((item) => (

                        <Button sx={{ width: isMobile ? "80%" : '30%', backgroundColor: item.can_view ? "default" : "gray", textDecoration: item.can_view ? "none" : 'line-through' }} variant="contained" disableElevation key={item.id} onClick={() => {
                            if (item.can_view) {
                                sessionStorage.setItem('eventid', item.id);
                                sessionStorage.setItem('allow_download', JSON.stringify(item.can_download));
                                addPathToCurrentURL(item.name)
                            }
                        }}>
                            {item.name}
                        </Button>
                    ))}
                </div>
            )}
        </div>
    );

}
