import { useState, useRef, ChangeEvent, KeyboardEvent } from 'react';
import { api } from '../axiosclient';
import { Button } from '@mui/material';

type passCodeProps = {
    alternateDigits: string,
    setAuthenticationStatus: React.Dispatch<React.SetStateAction<boolean>>,
    slug: string | undefined
}

export default function PasscodeInput({ alternateDigits, setAuthenticationStatus, slug }: passCodeProps) {
    const [values, setValues] = useState<string[]>(['', '', '', '', '']);
    const inputsRef = useRef<(HTMLInputElement | null)[]>([]);
    const [verificationMessage, setVerificationMessage] = useState("");

    const verifyPhoneNumber = () => {
        let phone = "";
        for (let i = 0; i < 5; i++) {
            phone += alternateDigits[i];
            phone += values[i];
        }
        api.post('/verifycontact', {
            phoneall: phone, url: slug
        })
            .then(response => {
                console.log(response.data)
                if (response.data.authenticated) {
                    // Saving the token
                    sessionStorage.setItem('authtoken', response.data.token);
                    setAuthenticationStatus(true);
                }
            })
            .catch(error => {
                console.error('Error verifying contact:', error);
                setVerificationMessage("Verification Failed");
            });
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        const newValues = [...values];
        newValues[index] = e.target.value;
        setValues(newValues);

        // Move to the next input if the current one is filled
        if (e.target.value && index < 4) {
            inputsRef.current[index + 1]?.focus();
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === 'Backspace' && !values[index] && index > 0) {
            inputsRef.current[index - 1]?.focus();
        }
    };

    return (
        <div style={{display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <h1 style={{fontFamily: "serif"}}>Phone Verification</h1>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '0.75rem'}}>
                {values.map((value, index) => (
                    <div style={{ display: 'flex' }}>
                        <p style={{ marginRight: '0.5rem' }}>{alternateDigits[index]}</p>
                        <input
                            key={index}
                            type="text"
                            maxLength={1}
                            value={value}
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            ref={(el) => (inputsRef.current[index] = el)}
                            style={{ width: '2rem', marginRight: '0.5rem', textAlign: 'center' }}
                        />
                    </div>
                ))}
            </div>
            <Button variant="contained" onClick={verifyPhoneNumber}>Verify</Button>
            <h4 style={{color:"red"}}>{verificationMessage}</h4>
        </div>
    );
};
