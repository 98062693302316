import { useState } from "react";
import './AvatarGrid.css'; // Import the CSS file for styling

export default function AvatarGrid({ avatars, selectedAvatars, setSelectedAvatars }){

    const handleAvatarClick = (index) => {
        setSelectedAvatars((prevSelected) => {
            if (prevSelected.includes(index)) {
                // If already selected, remove it from the selection
                return prevSelected.filter((i) => i !== index);
            } else {
                // If not selected, add it to the selection
                return [...prevSelected, index];
            }
        });
    };

    return (
        <div className="avatar-grid">
            {avatars.map((avatar, index) => (
                <div
                    className={`avatar ${selectedAvatars.includes(index) ? 'selected' : ''}`}
                    key={index}
                    onClick={() => handleAvatarClick(index)}
                >
                    <img src={avatar} alt={`Avatar ${index + 1}`} />
                </div>
            ))}
        </div>
    );
};
