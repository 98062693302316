import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

type alertProps = {
    alertState: boolean,
    setAlertState: React.Dispatch<React.SetStateAction<boolean>>,
    photocount: number,
    handleAgree: () => void; 
}


export default function AlertDialog({ alertState, setAlertState, photocount, handleAgree }: alertProps) {

    const handleClose = () => {
        setAlertState(false);
    };
    
    const handleDisagree = () => {
        handleClose();
    };
    return (
            <Dialog
                open={alertState}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Download Photos"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Download {photocount} selected photos. When images are ready to download, you will receive a link on email and text. Bulk download is allowed only ONCE. Estimated time is 15 minutes.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDisagree} color="primary">
                        Ignore
                    </Button>
                    <Button onClick={handleAgree} color="primary" autoFocus>
                        Download
                    </Button>
                </DialogActions>
            </Dialog>
    );
}