import { useState, useRef, useEffect } from "react";
import { Camera } from "react-camera-pro";
import Header from "./Header";
import { api } from '../axiosclient';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { PhotoViewOnlyMe } from "./PhotoViewOnlyMe"

const overlayImage = {
    position: 'absolute', /* Positions the overlay image */
    top: '0px', /* Adjusts the vertical position */
    left: '0px', /* Adjusts the horizontal position */
    width: '100%', /* Set the desired width for the overlay */
    height: '100vh', /* Maintains aspect ratio */
    opacity: '1.0', /* Optional: makes the overlay semi-transparent */
}

export default function CameraFindMe({ client_id, event_name }) {
    const camera = useRef(null);
    const [numberOfCameras, setNumberOfCameras] = useState(0);
    const [image, setImage] = useState(null);
    const [myPhotos, setMyPhotos] = useState([]);
    const [haveFoundPhotos, setHaveFoundPhotos] = useState(false);
    const [loadingPhotos, setLoadingPhotos] = useState(false);

    const buttonStyle = {
        padding: '10px 20px',
        fontSize: '16px',
        backgroundColor: '#007BFF',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer'
    };

    useEffect(() => {

    }, [haveFoundPhotos]);

    const findMyPhotos = (photo) => {
        // call the api to like the selected images
        setLoadingPhotos(true);
        api.post('/client/event/images/findme', {
            client_id: client_id,
            event_name: event_name,
            threshold: 0.4,
            img: photo,
        })
            .then(response => {
                console.log(response.data)
                setMyPhotos(response.data.images);
                setHaveFoundPhotos(true);
                setLoadingPhotos(false);
            })
            .catch(error => {
                console.log(`Error finding photos: ${error}`);
                setHaveFoundPhotos(true);
                setLoadingPhotos(false);
            });
    };

    if (loadingPhotos) {
        return (<div>
            <Header />
            <Box sx={{ display: 'flex', justifyContent: 'center', height: '100vh', alignItems: 'center' }}>
                <CircularProgress />
            </Box>
        </div>);
    }
    else if (haveFoundPhotos) {
        return <PhotoViewOnlyMe myPhotos={myPhotos} />
    } else {
        return (<div>
            <Header />
            <Camera ref={camera} numberOfCamerasCallback={setNumberOfCameras} errorMessages={{
                noCameraAccessible: 'No camera device accessible. Please connect your camera or try a different browser.',
                permissionDenied: 'Permission denied. Please refresh and give camera permission.',
                switchCamera:
                    'It is not possible to switch camera to different one because there is only one video device accessible.',
                canvas: 'Canvas is not supported.'
            }} />

            {image ? <div style={{
                position: 'relative',
                width: '100%',
                height: '100%'
            }}>
                <img src={image} alt='your clicked face' style={overlayImage} />
            </div> : <></>}



            <div style={{
                position: 'absolute',
                bottom: '20px',
                left: '50%',
                transform: 'translateX(-50%)',
                display: 'flex',
                gap: '10px'
            }}>
                {image == null ? <div>
                    <button
                        onClick={() => {
                            const photo = camera.current.takePhoto();
                            console.log(photo);
                            setImage(photo);
                        }}
                        style={buttonStyle}
                    >
                        Capture
                    </button>
                    <button
                        hidden={numberOfCameras <= 1}
                        onClick={() => {
                            camera.current.switchCamera();
                        }}
                        style={buttonStyle}
                    >Switch</button>
                </div> : <div>
                    <button
                        onClick={() => {
                            setImage(null);
                        }}
                        style={buttonStyle}
                    >
                        Reset
                    </button>
                    <button
                        onClick={() => {
                            findMyPhotos(image);
                        }}
                        style={buttonStyle}
                    >Find</button>
                </div>}
            </div>
        </div>
        )
    }
}
