import { useEffect, useState } from "react";
import { Gallery } from "react-grid-gallery";
import { Lightbox, Slide } from "yet-another-react-lightbox";
import Download from "yet-another-react-lightbox/plugins/download";
import "yet-another-react-lightbox/styles.css";
import { CustomImage } from "../images";
import InfiniteScroll from 'react-infinite-scroll-component';
import { ThreeDots } from 'react-loader-spinner';
import Header from "./Header";
import { useMediaQuery } from 'react-responsive';


type myPhotosProps = {
    myPhotos: CustomImage[],
}

export function PhotoViewOnlyMe({ myPhotos }: myPhotosProps) {
    const [images, setImages] = useState<CustomImage[]>([]);
    const [slides, setSlides] = useState<Slide[]>([]);
    const [index, setIndex] = useState(-1);
    const [hasmoreimages, sethasmoreimages] = useState(true);
    const [imagePageRequest, setImagePageRequest] = useState(0);
    const [loading, setLoading] = useState(false);
    const [containerHeight, setContainerHeight] = useState(0);
    const PAGINATE_SIZE = 30;
    const desktopRowHeight = 300;
    const mobileRowHeight = 200;
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const fetchMoreImages = () => {
        console.log("fetching more images", imagePageRequest);
        if (!loading) {
            setLoading(true);
            const updated_images = [...images, ...myPhotos.slice(imagePageRequest * PAGINATE_SIZE, (imagePageRequest + 1) * PAGINATE_SIZE)];
            setImages(updated_images);
            sethasmoreimages(myPhotos.length > (imagePageRequest + 1) * PAGINATE_SIZE);
            setImagePageRequest(imagePageRequest + 1);

            const sl = updated_images.map(({ original, width, height }: CustomImage) => ({
                src: original,
                width,
                height,
            }));
            setSlides(sl);
        }

        setLoading(false);
    }

    const handleClick = (index: number, item: CustomImage) => setIndex(index);

    useEffect(() => {
        if (containerHeight > 0 && containerHeight < window.innerHeight && hasmoreimages) {
            fetchMoreImages()
        }
    }, [containerHeight, imagePageRequest]);


    useEffect(() => {
        const myDiv = document.getElementById('imagegallery');
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                const newHeight = entry.contentRect.height;
                setContainerHeight(newHeight);
            }
        });

        if (myDiv) {
            resizeObserver.observe(myDiv);
        }

        console.log(myPhotos);

        const updated_images = [...images, ...myPhotos.slice(imagePageRequest * PAGINATE_SIZE, (imagePageRequest + 1) * PAGINATE_SIZE)];
        setImages(updated_images);
        const sl = updated_images.map(({ original, width, height }: CustomImage) => ({
            src: original,
            width,
            height,
        }));
        setSlides(sl);
        sethasmoreimages(myPhotos.length > (imagePageRequest + 1) * PAGINATE_SIZE);
        setImagePageRequest(imagePageRequest + 1);

    }, []);

    return (
        <div>
            <Header />

            <InfiniteScroll
                dataLength={images.length}
                next={fetchMoreImages}
                hasMore={hasmoreimages}
                loader={<div style={{ display: "flex", justifyContent: "center" }}><ThreeDots height={"80"} width={"80"} radius={"9"} color={"#808080"} ariaLabel={"three-dots-loading"} /></div>}
                endMessage=
                {<p style={{ textAlign: 'center' }}>
                    <b>Yay! You have seen it all</b>
                </p>
                }
            >
                <div id="imagegallery">
                    <Gallery
                        rowHeight={isMobile ? mobileRowHeight : desktopRowHeight}

                        images={images}
                        onClick={handleClick}
                        enableImageSelection={false}
                    />
                </div>
            </InfiniteScroll>

            <Lightbox
                slides={slides}
                open={index >= 0}
                index={index}
                toolbar={{
                    buttons: [
                        "close"
                    ]
                }}
                close={() => setIndex(-1)}
                plugins={[Download]}
            />
        </div>
    );
}