import { useState, useRef, useEffect } from "react";
import { TextField, Button, Box } from '@mui/material';
import Header from './Header';
import { useParams } from 'react-router-dom';
import { Card, CardMedia, CardContent, Typography } from '@mui/material';
import { Camera } from "react-camera-pro";
import { api } from '../axiosclient';


function CameraUpload({ setImage, setCameraState }) {
    const camera = useRef(null);
    const [numberOfCameras, setNumberOfCameras] = useState(0);

    const buttonStyle = {
        padding: '10px 20px',
        fontSize: '16px',
        backgroundColor: '#007BFF',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer'
    };

    return (<div>
        <Camera ref={camera} numberOfCamerasCallback={setNumberOfCameras} errorMessages={{
            noCameraAccessible: 'No camera device accessible. Please connect your camera or try a different browser.',
            permissionDenied: 'Permission denied. Please refresh and give camera permission.',
            switchCamera:
                'It is not possible to switch camera to different one because there is only one video device accessible.',
            canvas: 'Canvas is not supported.'
        }} />


        <div style={{
            position: 'absolute',
            bottom: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            display: 'flex',
            gap: '10px'
        }}>
            <div>
                <button
                    onClick={() => {
                        const photo = camera.current.takePhoto();
                        setImage(photo);
                        setCameraState(false);
                    }}
                    style={buttonStyle}
                >
                    Capture
                </button>
                <button
                    hidden={numberOfCameras <= 1}
                    onClick={() => {
                        camera.current.switchCamera();
                    }}
                    style={buttonStyle}
                >Switch</button>
            </div>
        </div>
    </div>
    )
}


function ImageCard({ img }) {
    return (
        <Card>
            <CardMedia
                component="img"
                image={img}
                alt="Description of image"
            />
        </Card>
    );
};

export default function UserForm() {
    const [name, setName] = useState('');
    const [eventName, setEventName] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [email, setEmail] = useState('');
    const { event_id } = useParams();
    const [cameraState, setCameraState] = useState(false);
    const [userImage, setUserImage] = useState(null);
    const [uploadMessage, setUploadMessage] = useState(null);

    useEffect(() => {

    }, [cameraState]);

    useEffect(() => {
        api.post('/event/name', {
            event_id: event_id
        })
            .then(response => {
                setEventName(response.data);
            })
            .catch(error => {
                console.log(`Error getting event name ${error}`);
            });
    }, []);


    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission logic here
        setUploadMessage("Uploading...");
        api.post('/guest/event/images/upload', {
            img: userImage,
            event_id: event_id,
            name: name,
            contact: contactNumber,
            email_id: email
        })
            .then(response => {
                console.log(response.data);
                setName('');
                setEmail('');
                setContactNumber('');
                setUserImage(null);
                setUploadMessage('Image uploaded successfully, Wait for an email or text message for accessing photos in a week!')
            })
            .catch(error => {
                console.log(`Error uploading information`);
                setUploadMessage('Retry, Failed to upload!')
            });



    };
    if (cameraState == true) {
        return <CameraUpload setImage={setUserImage} setCameraState={setCameraState} />
    } else {
        return (
            <div>
                <Header />
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: 400,
                        margin: 'auto',
                        marginTop: '20px',
                        padding: 2,
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <CardContent>
                        <Typography variant="h5" component="div">
                            {eventName}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Don't miss on the beautiful moments! Register to get your photos!
                        </Typography>
                    </CardContent>
                    <TextField
                        required
                        label="Name"
                        variant="outlined"
                        margin="normal"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        required
                        label="Contact Number"
                        variant="outlined"
                        margin="normal"
                        value={contactNumber}
                        onChange={(e) => setContactNumber(e.target.value)}
                    />
                    <TextField
                        label="Email"
                        variant="outlined"
                        margin="normal"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button variant="contained" onClick={() => setCameraState(true)} sx={{ marginTop: 2, marginBottom: 2 }}>
                        {userImage ? "Retake Photo" : "Take Photo"}
                    </Button>
                    {userImage ? <ImageCard img={userImage} /> : <></>}

                    {userImage ? <Button variant="contained" type="submit" sx={{ marginTop: 2, marginBottom: 2 }}>
                        Submit
                    </Button> : <></>}

                    {uploadMessage ? <CardContent>
                        <Typography variant="body2" color="text.secondary">
                            {uploadMessage}
                        </Typography>
                    </CardContent> : <></>}

                </Box>
            </div>
        );
    }
}