import axios from 'axios';

// const api = axios.create({
//   baseURL: 'http://localhost:8080/photovu/api'
// });

const api = axios.create({
  baseURL: 'https://api.cosecantai.com/photovu/api'
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
      // Add the authorization header
      const access_token = sessionStorage.getItem('authtoken');
      if (access_token != null) {
          config.headers['Authorization'] = `Bearer ${access_token}`;
      }
      return config;
  },
  (error) => {
      // Handle the error
      return Promise.reject(error);
  }
);


export {api};